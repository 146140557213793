import React from 'react';
import {Modal, Button, InputGroup, FormControl} from 'react-bootstrap';


export default class SrcModal extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            pass: null,
            isAuth: false,
            goodPwd: null,
            src: '',
            defaultSrc: this.props.defaultSource
        }
    }

    handlePass = (e) => {
        let value = e.target.value;
        this.setState({pass: value})
    };
    handleAuth = () => {
        this.state.pass === null && this.setState({pass: ""})
        if(this.state.pass === 'peachart'){
            this.setState({isAuth: true, goodPwd: true})
        }
        else {
            this.setState({goodPwd: false})
        }
    };

    handleSrc = (e) => {
        let value = e.target.value;
        this.setState({ src: value });
    }

    changeSrc = () => {
        this.props.updateSrc(this.state.src);
        this.props.handleClose();
    }

    bringDefaultSrc = () => {
        this.props.putDefault();
        this.props.handleClose();
    }

    Login = () => (
    <InputGroup className="mb-3">
        <InputGroup.Prepend>
        <InputGroup.Text id="basic-addon1">Authenticate</InputGroup.Text>
        </InputGroup.Prepend>
        <FormControl
        placeholder="Passphrase"
        aria-label="Passphrase"
        aria-describedby="basic-addon1"
        type="password"
        onChange={(e) => this.handlePass(e)}
        />
    </InputGroup>
    );

    Source = () => (
    <InputGroup className="mb-3">
      <InputGroup.Prepend>
        <InputGroup.Text id="basic-addon1">Live Player Source</InputGroup.Text>
      </InputGroup.Prepend>
      <FormControl
        placeholder="Source URL"
        aria-label="Source"
        aria-describedby="basic-addon1"
        onChange={(e) => this.handleSrc(e)}
      />
    </InputGroup>

    )

    render() {
        return (
          <Modal
            show={this.props.show}
            onHide={this.props.handleClose}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Change Live Player Source</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <h6 style={{color: "green"}}>
                {this.state.goodPwd
                  && "You are now authenticated"}
              </h6>
              <h6 style={{color: "red"}}>
                {this.state.pass !== null && this.state.goodPwd === false
                  && "Wrong password"}
              </h6>
              {!this.state.isAuth ? <this.Login /> : <this.Source />}
            </Modal.Body>
            <Modal.Footer>
              {this.state.isAuth && <Button variant="secondary" onClick={this.bringDefaultSrc}>
                Revert changes
              </Button>}
              <Button variant="primary" onClick={!this.state.isAuth ? this.handleAuth : this.changeSrc}>
                {!this.state.isAuth ? "Authenticate" : "Change source"}
              </Button>
            </Modal.Footer>
          </Modal>
        );
    }
    
}