import React from 'react';
import fab from './assests/fab5g.svg';
import logo from './assests/pp1.png';
import './Home.css'
import Footer from './Footer';
import {Nav, Navbar, Button} from 'react-bootstrap';
import Player from './THEOplayerWrapper';
import LivePlayer from './LivePlayer';

export default function Home(props) {
    let source = [
        {
            sources: [{
                // src: "//cdn.theoplayer.com/video/elephants-dream/playlist.m3u8",
                src: process.env.PUBLIC_URL + '/static/media/Fericire eterna.mp4',
                // src: 'http://peacharthub.eu/static/media/Fericire%20eterna.mp4',
                // type: "application/x-mpegurl"
                type: "video/mp4",
            }],
            vr:{
                360: true
            }
        },
        {
            sources: [{
                // src: "//cdn.theoplayer.com/video/elephants-dream/playlist.m3u8",
                src: process.env.PUBLIC_URL + '/static/media/Fericire eterna -small.mp4',
                // type: "application/x-mpegurl"
                type: "video/mp4",
            }],
            vr:{
                360: true
            }
        },
    ]
    let playerSource1 = {
        sources: [{
            "src": process.env.PUBLIC_URL + "/static/media/Fericire%20eterna%20edit2-%20small.mp4",
            "type": "video/mp4"
        }],
        // VR configuration
        vr: {
            360: true
        },
        poster: 'http://peacharthub.eu/static/media/Thumbs/1-2049%20Fericire%20eterna%20360.jpg'
    };
    let playerSource2 = {
        sources: [{
            "src": process.env.PUBLIC_URL + "/static/media/2-Flowers4Hearts%20before%20Lockdown.mp4",
            "type": "video/mp4"
        }],
        // VR configuration
        vr: {
            360: true
        },
        poster: 'http://peacharthub.eu/static/media/Thumbs/2-Flowers4Hearts%20before%20Lockdown.jpeg'
    };
    let playerSource3 = {
        sources: [{
            "src": process.env.PUBLIC_URL + "/static/media/3-Lockdown%20silence.MP4",
            "type": "video/mp4"
        }],
        // VR configuration
        vr: {
            360: true
        },
        poster: 'http://peacharthub.eu/static/media/Thumbs/3-Lockdown%20silence.jpeg'
    };
    let playerSource4 = {
        sources: [{
            "src": process.env.PUBLIC_URL + "/static/media/4-Fields%20of%20Gold%20of%20King%20Michael%20...%20Park.mp4",
            "type": "video/mp4"
        }],
        // VR configuration
        vr: {
            360: true
        },
        poster: 'http://peacharthub.eu/static/media/Thumbs/4-Fields%20of%20Gold%20of%20King%20Michael%20...%20Park.jpeg'
    };

    let playerSourceMain = {
        sources: [{
            "src": "https://5e448368eb02f.streamlock.net:443/live/test360/playlist.m3u8",
            "type": "application/x-mpegurl"
        }],
        // VR configuration
        vr: {
            360: true
        }
    };
    return (
      <div className="web-container">
        <div className="header">
          <div className="menu">
            <Navbar bg="light" expand="lg" id="nav">
              <Navbar.Brand href="#home">
                <div className="logo">
                  <img src={logo} alt="logo" />
                </div>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav" />
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ml-auto">
                  <Nav.Link href="http://peachartcrew.com/">Home</Nav.Link>
                  <Nav.Link href="http://peachartcrew.com/about/">
                    About
                  </Nav.Link>
                  <Nav.Link href="http://peachartcrew.com/projects/">
                    Projects
                  </Nav.Link>
                  <Nav.Link href="http://peachartcrew.com/our-partners/">
                    Partners
                  </Nav.Link>
                  <Nav.Link href="http://peachartcrew.com/contact-us/">
                    Contact Us
                  </Nav.Link>
                </Nav>

                <a href="http://peachartcrew.com/send_an_enquiry/">
                  <Button variant="primary">Join the PeachART Crew</Button>
                </a>
              </Navbar.Collapse>
            </Navbar>
          </div>
        </div>
        <div className="layout top">
          <h2>
            Projects We Develop
            <br />
            <span>PeachART HUB</span>
          </h2>
          {/* <img src={section} style={{background: '#6cc083', width: '100%', height: 'auto'}}/> */}
        </div>
        <div className="content">
          <img src={fab} alt="fab" />
        </div>
        <div className="snd-content"></div>
        <div className="multimedia">
          <div className="title">
            <h2>Content Samples</h2>
          </div>
          <div className="video">
            <div className={"PlayerDiv"}>
              <Player
                source={playerSource1}
                preload={true}
                onPlay={() => {
                  console.log("The player has started playing.");
                }}
              />
              <h3>Content Sample - 360 Recorded Theatrical Performance</h3>
            </div>
            <div className={"PlayerDiv"}>
              <Player
                source={playerSource2}
                preload={true}
                onPlay={() => {
                  console.log("The player has started playing.");
                }}
              />
              <h3>
                Content Sample - 360 Recorded ARTherapy Studio Presentation
              </h3>
            </div>
            <div className={"PlayerDiv"}>
              <Player
                source={playerSource3}
                preload={true}
                onPlay={() => {
                  console.log("The player has started playing.");
                }}
              />
              <h3>Content Sample - 360 Recorded Lockdown Silence</h3>
            </div>
            <div className={"PlayerDiv"}>
              <Player
                source={playerSource4}
                preload={true}
                onPlay={() => {
                  console.log("The player has started playing.");
                }}
              />
              <h3>Content Sample - 360 Recorded Street Performance</h3>
            </div>
            <div className={"PlayerDivMain"}>
              <LivePlayer src={playerSourceMain} dsrc={playerSourceMain} />
            </div>
          </div>
        </div>
        <div className="layout bottom">
          <h2>Do you like our approach? Join The Crew!</h2>
          <a href="http://peachartcrew.com/send_an_enquiry/">
            <Button variant="outline-light">Let's work together</Button>
          </a>
          {/* <img src={section} style={{background: '#6cc083', width: '100%', height: 'auto'}}/> */}
        </div>
        <div>
          <Footer />
        </div>
      </div>
    );
}