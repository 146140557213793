import React, {useState} from 'react';
import SrcModal from './Modal';
import {Button} from 'react-bootstrap';
import Player from "./THEOplayerWrapper";

export default class LivePlayer extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            show: false,
            defaultValue: this.props.dsrc,
            src: this.props.src
        }
    }

    handleClose = () => this.setState({show: false});
    handleShow = () => this.setState({show: true});


    updateSrc = (source) => {
        let localSrc = this.state.src;
        localSrc.sources[0].src =
          source;
          localSrc.sources[0].type = "video/mp4";
          
        this.setState({src: localSrc});
    };

    putDefault = () => {
        
        this.setState({src: {
        sources: [{
            "src": "https://5e448368eb02f.streamlock.net:443/live/test360/playlist.m3u8",
            "type": "application/x-mpegurl"
        }],
        // VR configuration
        vr: {
            360: true
        }}});
    }
    
    render(){

    const modalProps = {
      show: this.state.show,
      handleClose: this.handleClose,
      updateSrc: this.updateSrc,
      putDefault: this.putDefault,
      defaultSource: this.props.src.sources[0].src,
    };
        console.log(this.state)
        return (
          <>
            <h3>Content Sample - 360 Live Streaming - Contact us for demo!</h3>
            {/* <span>
              {this.state.src.sources && this.state.src.sources[0].src}
            </span> */}
            <Player
              source={this.state.src}
              preload={false}
              onPlay={() => {
                console.log(
                  "The player has started playing." +
                    this.state.src.sources[0].src
                );
              }}
            />
            <SrcModal {...modalProps} />
            <Button
              variant="primary"
              onClick={this.handleShow}
              style={{ marginTop: "20px" }}
            >
              Change Live Player Source
            </Button>
          </>
        );
    }
    
}