import React from "react";
import './THEOplayerWrapper.css';

class Player extends React.Component {
    _player = null;
    _el = React.createRef();

    componentDidMount() {
        // props like the source, or the sourcedesciption as a whole can be passed through
        // the can then be used to be called on the player itself
        const { source, onPlay, preload } = this.props;

        if (this._el.current) {
            this._player = new window.THEOplayer.Player(this._el.current, {
                libraryLocation: "https://cdn.myth.theoplayer.com/2fb65c22-7453-437d-814e-a9b34a782961"
            });

            this._player.source = source;
            this._player.addEventListener('play', onPlay)
            this._player.preload = preload ? "auto" : "none";
            this._player.addEventListener("representationchange", function (event) {
          
                // specifies how big bandwidth is required for the current quality
                var representationBandwidth = event.representation.bandwidth;
                // player.abr.targetBuffer is 20 by default
                var targetBuffer = this._player.abr.targetBuffer;
      
                // SD
                if (representationBandwidth < 3000000) {
                  targetBuffer = 20;
                } else if (representationBandwidth > 3000000 && representationBandwidth < 6000000) {
                  // HD
                  targetBuffer = 7;
                } else if (representationBandwidth > 6000000) {
                  //FHD, UHD, 4K
                  targetBuffer = 2;
                } 
      
                this._player.abr.targetBuffer = targetBuffer;
                console.log('representationchange - updated player.abr.targetBuffer: ' + this._player.abr.targetBuffer);
              });
              if (window.THEOplayer.videojs.browser.IS_ANDROID || window.THEOplayer.videojs.browser.IS_IOS ) {
                this._player.abr.targetBuffer = 3;
            }
        }
    }

    componentWillUnmount() {
      if (this._player) {
        this._player.destroy();
      }
    }

    render() {
        return ( <
            div
            // vjs-16-9 THEOplayer are not necessary, but just added for demo purposes
            className = { "theoplayer-container video-js theoplayer-skin vjs-16-9 THEOplayer" }
            // The ref prop here is key it returns the actual dom element and not the virtual react dom elements
            // Which is need by the player
            ref = { this._el }
            />
        );
    }
}

export default Player;